<template>
  <div>
    <!-- <h2 v-if="title" class="text-uppercase font-weight-bold default--text my-5">
      {{ title }}
    </h2> -->
    <GiftCard
      v-for="gift in giftList"
      :key="gift.userGiftCertificateId"
      :giftCertificate="gift"
      :mode="mode"
      class="mb-4 pb-4"
      @add="$emit('reload')"
    />
  </div>
</template>
<style scoped lang="scss">
h2 {
  font-size: 20px;
}
.widget {
  min-height: 360px;
  background-size: contain;
}
</style>
<script>
import GiftCard from "./GiftCard.vue";
export default {
  components: { GiftCard },
  name: "GiftList",
  props: {
    title: {
      type: String,
      required: false
    },
    giftList: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: "full"
    }
  }
};
</script>
