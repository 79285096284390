<template>
  <div>
    <v-container fluid v-if="headerProposals.length > 0">
      <component
        :is="headerMode"
        :title="headerTitle"
        :proposals="headerProposals"
        paginationClass="pagination-header"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
    <v-container fluid class="pa-0">
      <ProfileTitle :category="category" />
    </v-container>

    <v-container class="pa-2 my-4">
      <GiftList
        v-if="filteredAutoAssignedGifts.length > 0"
        :giftList="filteredAutoAssignedGifts"
        :title="$t('profile.gifts.coupon')"
        @reload="reload"
      />
    </v-container>
    <!-- Invita un amico -->
    <v-container class="pa-2 my-4" v-if="isBetaTester"
      ><InviteCard
        :title="$t('invite.tellFriend.title')"
        :description="$t('invite.tellFriend.desc')"
        :src="tellFriendImage"
        v-if="showInviteStatic && activeCampaign"
    /></v-container>
    <v-container class="pa-2 my-4">
      <GiftList
        v-if="filteredManualAssignedGifts.length > 0"
        :giftList="filteredManualAssignedGifts"
        :title="$t('profile.gifts.advantages')"
        :showInviteStatic="showInviteStatic"
        @reload="reload"
      />
    </v-container>
    <v-container class="pa-2 my-4" v-if="noCouponFound">
      <h3>
        {{ $t("profile.gifts.noCouponFound") }}
      </h3>
    </v-container>
    <v-container class="pa-2 my-4" v-if="footerProposals.length > 0">
      <component
        :is="footerMode"
        :title="footerTitle"
        :proposals="footerProposals"
        paginationClass="pagination-footer"
        :cols="1"
        :sm="2"
        :md="2"
        :lg="2"
      />
    </v-container>
  </div>
</template>
<style scoped lang="scss">
.invite-card {
  height: 210px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: auto;
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";

import GiftList from "@/components/gift/GiftList.vue";
import InviteCard from "@/components/gift/InviteCard.vue";
import login from "~/mixins/login";
import category from "~/mixins/category";
import deliveryReactive from "~/mixins/deliveryReactive";
import { mapProposalComponent } from "@/commons/service/ebsn";
import userService from "~/service/userService";
import InviteService from "~/service/inviteService";
import get from "lodash/get";
import ProfileTitle from "@/components/profile/ProfileTitle.vue";

export default {
  name: "ProfileGift",
  mixins: [category, deliveryReactive, login],
  components: {
    GiftList,
    InviteCard,
    ProfileTitle
  },
  data() {
    return {
      autoAssignedGifts: [],
      manualAssignedGifts: []
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      isBetaTester: "cart/isBetaTester"
    }),

    ...mapProposalComponent({
      header: "header",
      footer: "footer"
    }),
    filteredAutoAssignedGifts() {
      return this.autoAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    },
    showInviteStatic() {
      var result = true;
      for (var i = 0; i < this.manualAssignedGifts.length > 0; i++) {
        if (this.manualAssignedGifts[i].giftCertificateId == 355) {
          result = false;
          break;
        }
      }
      return result;
    },
    filteredManualAssignedGifts() {
      return this.manualAssignedGifts.filter(
        gift =>
          !get(
            gift,
            "categoryProposal.metaData.category_proposal_type.HIDE_IN_LIST"
          ) == true
      );
    },
    tellFriendTitle() {
      return get(
        this.category,
        "metaData.category_info.TELL_FRIEND_TITLE",
        "Presenta un amico"
      );
    },
    tellFriendDescription() {
      return get(
        this.category,
        "metaData.category_info.TELL_FRIEND_DESCRIPTION",
        "Invita i tuoi amici su dupliclick.it e ricevi un buono sconto del valore di 5€ per ogni amico che effettua la sua prima spesa."
      );
    },
    tellFriendImage() {
      return get(
        this.category,
        "metaData.category_info." +
          (this.$vuetify.breakpoint.xs
            ? "TELL_FRIEND_IMAGE_MOBILE"
            : "TELL_FRIEND_IMAGE_DESKTOP"),
        this.$vuetify.breakpoint.xs
          ? "/img_layout/invite/invite_sm.png"
          : "/img_layout/invite/invite_lg.png"
      );
    },
    noCouponFound() {
      return (
        this.filteredManualAssignedGifts &&
        this.filteredManualAssignedGifts.length <= 0 &&
        this.filteredAutoAssignedGifts &&
        this.filteredAutoAssignedGifts.length <= 0
      );
    }
  },
  methods: {
    async reload() {
      this.autoAssignedGifts = await userService.listGiftCertificate("auto");
      this.manualAssignedGifts = await userService.listGiftCertificate(
        "manual"
      );
    }
  },
  async mounted() {
    let response = await InviteService.getPersonalCode();
    if (response) {
      this.personalCode = response;
      this.activeCampaign = this.personalCode && this.personalCode.tellFriend;
    }
    this.reload();
  }
};
</script>
