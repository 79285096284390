<template>
  <v-card
    class="gift-card  default--text"
    :class="mode"
    :flat="mode == 'checkout'"
  >
    <v-row>
      <v-col
        :cols="mode == 'full' ? 12 : 2"
        :sm="mode == 'full' ? 2 : 1"
        class="d-flex justify-center align-center"
      >
        <v-img v-if="src" :src="src" alt="Invita un amico" class="rounded-sm" />
        <div
          v-else
          class="certificate-box is-card d-flex flex-column justify-space-around align-center"
          :class="cssClass"
          @click.prevent.stop="handleClick"
          outlined
        >
          <span class="icon-circle">
            <v-icon size="48" color="primary">{{ icon }}</v-icon>
          </span>
        </div>
      </v-col>

      <v-col
        :cols="mode == 'full' ? 12 : 10"
        :sm="mode == 'full' ? 10 : 11"
        class="d-flex flex-column body-column"
      >
        <div class="text-body-1 font-weight-bold">
          {{ name }}
        </div>
        <div
          v-if="!hideEndDate && certificateExpiryDate"
          class="expiration text-caption grey lighten-4 my-2"
        >
          <span class="font-weight-bold text-uppercase">
            {{ $t("checkout.expires") }} </span
          >: {{ $dayjs(giftCertificate.endDate).format("D MMMM YYYY") }}
        </div>
        <div class="description" v-html="description"></div>
        <div
          class="text-body-2 mt-2"
          v-if="mode == 'full' && listUsedCertificates.length > 0"
        >
          Utilizzato in questi ordini:
          <v-chip
            v-for="used in listUsedCertificates"
            :key="used.orderId"
            :to="{ name: 'Order', params: { orderId: used.orderId } }"
            class="mr-2"
          >
            <span class="text-caption">#{{ used.orderId }}</span>
          </v-chip>
        </div>
        <div
          class="text-body-2 mt-2"
          v-if="!isAssignable && listAvailableCertificates.length > 0"
        >
          <i>Buoni utilizzabili:</i><br />
          <div
            v-for="available in listAvailableCertificates"
            :key="available.userGiftCertificateId"
            class="py-1 d-flex"
          >
            <div class="d-flex flex-column">
              <div>
                <span>{{ available.name }}</span>
                <strong v-if="available.amount">
                  &nbsp;{{ $n(available.amount, "currency") }}
                </strong>
              </div>
              <div
                v-if="!hideEndDate && !certificateExpiryDate"
                class="expiration text-caption grey lighten-4 my-2 d-block"
              >
                <span class="font-weight-bold text-uppercase">
                  {{ $t("checkout.expires") }} </span
                >: {{ $dayjs(available.endDate).format("D MMMM YYYY") }}
              </div>
            </div>

            <v-spacer />
            <v-btn
              v-if="available.cartId != cart.cartId"
              small
              depressed
              color="primary"
              @click="addUserGift(available.userGiftCertificateId)"
            >
              {{ $t("checkout.giftCodeUseNow") }}
            </v-btn>
            <v-btn
              v-else
              small
              depressed
              color="primary"
              outlined
              @click="remove(available.userGiftCertificateId)"
            >
              {{ $t("checkout.giftCodeRemove") }}
            </v-btn>
          </div>
        </div>

        <v-spacer />

        <v-card-actions class="pa-0 mt-2 space-between align-center">
          <v-btn
            v-if="mode == 'full' && link"
            outlined
            :large="mode == 'full'"
            :small="mode == 'checkout'"
            depressed
            color="primary"
            :to="link"
          >
            {{ btnLabel }}
          </v-btn>
          <v-spacer />
          <v-btn
            v-if="isAssignable && userGiftCertificateId != null"
            :large="mode == 'full'"
            :small="mode == 'checkout'"
            depressed
            outlined
            color="primary"
            @click="remove(userGiftCertificateId)"
            >{{ $t("checkout.giftCodeRemove") }}
          </v-btn>
          <v-btn
            v-else-if="isAssignable"
            :large="mode == 'full'"
            :small="mode == 'checkout'"
            depressed
            color="primary"
            @click="add"
            >{{ $t("checkout.giftCodeUseNow") }}</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped lang="scss">
.gift-card {
  .icon-circle {
    border: 2px var(--v-primary-base) solid !important;
    border-radius: 50%;
    padding: 20px;
    .v-icon {
      font-size: 18px;
    }
  }
  border-bottom: 1px solid var(--v-grey-lighten4);
  border-radius: 0px;
  //padding-bottom: 4px;
  .body-column {
    padding-left: 8px;
  }

  &.full {
    border-radius: 4px;
    .body-column {
      padding: 20px !important;
    }
  }
  .v-image {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .expiration {
    width: fit-content;
    padding: 2px;
  }
  .description {
    ::v-deep ul {
      padding-left: 24px !important;
      li {
        list-style: disc !important;
      }
    }
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "GiftCard",
  mixins: [giftCertificate],
  props: {
    mode: {
      type: String,
      default: "full"
    }
  },
  certificateExpiryDate() {
    const expirationType =
      this.giftCertificate &&
      this.giftCertificate.redemption == "autoassignment"
        ? true
        : false;
    return expirationType;
  }
};
</script>
